<template>
  <div class="not-found">
    <img
      src="https://assets-global.website-files.com/653a7ffaba9b9e058eed1dfe/655597e9bbec3a2e8799a246_error-graphic.webp"
      alt="404 Image"
      class="not-found-image w-56"
    />
    <div class="not-found-text">
      <h1
        class="mb-4 text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-5xl dark:text-white"
      >
        {{ $t("404.title") }}
      </h1>
      <p>
        {{ $t("404.description") }}
      </p>
      <router-link to="/" class="font-bold">{{ $t("404.action") }}</router-link>
    </div>
  </div>
  <ClientOnly>
    <cookie></cookie>
  </ClientOnly>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.not-found-image {
  max-width: 100%;
  max-height: 300px; /* Adjust the height as needed */
  margin-bottom: 20px;
}

.not-found-text {
  text-align: center;
}

/* Add your additional styles here */
</style>
